import { useContext } from "react";
import { LangContext, UserContext } from "./App.js";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Axios from "axios";

export default function LoginUserButton() {

    /* --- actual location --- */
    const location = useLocation();

    /* --- lang context --- */
    const {langs} = useContext(LangContext);

    /* --- user context --- */
    const {user} = useContext(UserContext);

    /* --- redirects --- */
	const navigate = useNavigate();

    // log out
	const logOut = () => {
		Axios({
			method: "DELETE",
			withCredentials: true,
			url: `${process.env.REACT_APP_SERVER_URL}/login`
		}).then((res) => {
            const setUserFunc = user.setUser;
			user.setUser({
                isLogged: false,
                user: {},
                setUser: setUserFunc
            });
            redirect("/login");
		});
	};

    /* --- navigate --- */
	const redirect = (location) => {
		navigate(location);
	};
	
	// component
	return (
        <div className="login-user-btn-component">
            {   // user logged in: render name and log out button
                user.isLogged &&
                <div className="user-btn-logout">
                    <button onClick={logOut}>
                        {langs.text.account.logOut[langs.lang]}
                    </button>
                </div>
            }
            {   // not logged in: render log in button in not log in pages
                !user.isLogged && location.pathname !== "/login" &&
                <Link to="/login" className="button">
                    {langs.text.account.logIn[langs.lang]}
                </Link>
            }
            {   // not logged in: render sign up button in log in page
                !user.isLogged && location.pathname === "/login" &&
                <Link to="/register" className="button">
                    {langs.text.account.signUp[langs.lang]}
                </Link>
            }
        </div>
	);
}