import { useContext } from "react";
import { LangContext } from "./App.js";

export default function LanguagesSelector() {

    // use context to get languages
    const {langs, setLangs} = useContext(LangContext);

    // change current language
    const changeLang = (lang) => {
        localStorage.setItem("language", lang);
        const availLangs = langs.available;
        const textLangs = langs.text;
        const setLangsFunc = langs.setLangs;
        setLangs({
			lang: lang,
			available: availLangs,
			text: textLangs,
            setLangs: setLangsFunc
        });
    };

    const displayAvailableLangs = langs.available.map(lang => {
        const isButtonSelected = lang === langs.lang ? "selected" : "";
        return (
            <button
                className={isButtonSelected}
                onClick={e => changeLang(lang)}
                key={lang}>{langs.text.languages[lang][lang]}
            </button>
        );
    });

    // component
	return (
        <div className="lang-sel-component">
            <button className="lang-sel-component-selected triangle">
                {langs.text.languages[langs.lang][langs.lang].substr(0, 3).toUpperCase()}
            </button>
            <div className="lang-sel-component-others">
                {displayAvailableLangs}
            </div>
        </div>
	);
}