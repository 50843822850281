/* --- Import react --- */
import React from 'react';
import { useState, useEffect, createContext } from 'react';
/* --- Import react router --- */
import { Routes, Route } from "react-router-dom"; 
import UseRedirectToHttps from './useRedirectToHttps';
/* --- Import languages --- */
import Language from "../objects/Language";
/* --- Import Axios for API calls --- */
import Axios from "axios";
/* --- Import styles --- */
import "../styles/main.css";
/* --- Import components --- */
import Header from "./Header";
import Loading from "./Loading";
import LearningPaths from "./LearningPaths";
import Results from "./Results";
import Login from "./Login";
import Register from "./Register";
import RecoverPassword from "./RecoverPassword";
import ResetPassword from "./ResetPassword";
import NotFound from "./NotFound";
import Product from "./Product";
/* --- languages context --- */
export const LangContext = createContext();
/* --- logged user context --- */
export const UserContext = createContext();

export default function App() {

	// force https
	UseRedirectToHttps();
	
	// used in every component to display the selected language
	const [langs, setLangs] = useState({
		lang: "en",
		available: "en",
		text: {}
	});
	// used in components that need the info of the logged user
	const [user, setUser] = useState({
		isLogged: false,
		user: {}
	});

	// useEffect is only called once (twice in development)
	useEffect(() => {

		// configure languages [0] is default
		const availableLangs = ["en", "es"];
		// get browser language
		let browserLanguage =
			navigator.language ||
			navigator.userLanguage; 
		browserLanguage = browserLanguage.substring(0, 2);
		browserLanguage =
			availableLangs.includes(browserLanguage) ?
				browserLanguage : browserLanguage[0];
		// check lang in localStorage
		const localLang = localStorage.getItem("language");
		if (localLang && availableLangs.includes(localLang)) {
			browserLanguage = localLang;
		} else {
			localStorage.removeItem("language");
		}
		// assign browser language and text to state
		const languages = new Language();
		setLangs({
			lang: browserLanguage,
			available: availableLangs,
			text: languages.getText(),
			setLangs
		});

		// configure user session
		Axios({
			method: "GET",
			withCredentials: true,
			url: `${process.env.REACT_APP_SERVER_URL}/users`,
		}).then(
			(res) => {
				setUser({
					isLogged: true,
					user: res.data.user, 
					setUser
				})
			}
		).catch(
			() => setUser({
				isLogged: false,
				user: {}, setUser
			})
		);

	}, []);

	// display components only after language has loaded
	const displayApp = () => {
		// if languages loaded
		if(Object.keys(langs.text).length){
			// return all the app components here
			return (
				<div>
					<Header />
					<Routes>
						<Route path="/" element={<LearningPaths />} />
						<Route path="paths" element={<LearningPaths />} />
						<Route path="paths/:pathURL" element={<LearningPaths />} />
						<Route path="results" element={<Results />} />
						<Route path="results/:pathURL" element={<Results />} />
						<Route path="login" element={<Login />} />
						<Route path="register" element={<Register />} />
						<Route path="recover" element={<RecoverPassword />} />
						<Route path="reset" element={<ResetPassword />} />
						<Route path="product/:id" element={<Product />} />
						<Route path="*" element={<NotFound />} />
					</Routes>
				</div>
			);
		}
		// loading screen
		return <Loading />;
	};
	
	// component
	return (
		<LangContext.Provider value={{langs, setLangs}}>
			<UserContext.Provider value={{user, setUser}}>
				{
					displayApp()
				}
			</UserContext.Provider>
		</LangContext.Provider>
	);
}