import { useContext, useState, useEffect } from "react";
import { LangContext, UserContext } from "./App.js";
import { Link, useNavigate, Navigate } from "react-router-dom";
import Axios from "axios";
import Loading from "./Loading";
import TagManager from 'react-gtm-module';

export default function Login() {

    /* --- lang context --- */
    const {langs} = useContext(LangContext);

    /* --- user context --- */
    const {user} = useContext(UserContext);

    /* --- redirects --- */
	const navigate = useNavigate();

    /* --- states for fields --- */
    const [loginEmail, setLoginEmail] = useState("");
	const [loginPassword, setLoginPassword] = useState("");
	const [loginResult, setLoginResult] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    /* --- data layer tag manager --- */
    useEffect(() => {
        /* --- data layer --- */
        const tagManagerArgs = {
            dataLayer: {
                event: 'pageview',
                page: window.location.href
            }
        }
        TagManager.dataLayer(tagManagerArgs);
    }, []);

    /* --- Log in API --- */
    const login = () => {

        // loading screen
		setIsLoading(true);

        // log in API
		Axios({
			method: "POST",
			data: {
				email: loginEmail,
				password: loginPassword
			},
			withCredentials: true,
			url: `${process.env.REACT_APP_SERVER_URL}/login`,

        // log in success
		}).then((res) => {

            // get user data API
            const setUserFunc = user.setUser;
            Axios({
                method: "GET",
                withCredentials: true,
                url: `${process.env.REACT_APP_SERVER_URL}/users`,

            // user data success
            }).then(
                (res) => {
                    const userData = res.data.user;
                    user.setUser({
                        isLogged: true,
                        user: userData,
                        setUser: setUserFunc
                    });
                    // data layer
                    const tagManagerArgs = {
                        dataLayer: {
                            event: 'login'
                        }
                    }
                    TagManager.dataLayer(tagManagerArgs);
                    // redirect
                    redirect("/");
                }

            // error getting user data
            ).catch(
                () => {
                    user.setUser({
                        isLogged: false,
                        user: {},
                        setUser: setUserFunc
                    })
                    setIsLoading(false);
                }
            );

        // log in error
		}).catch((error) => {
            addErrorsClass();
			setLoginResult(langs.text.account.logInError[langs.lang]);
            setIsLoading(false);
		});
	};

    /* --- every time the language is changed --- */
    useEffect(() => {
        // errors do not translate, this sets them empty
        setLoginResult("");
    }, [langs.lang]);

    /* --- prevent form submit --- */
    const submitForm = (e) => {
        e.preventDefault();
    };

    /* --- navigate --- */
	const redirect = (location) => {
		navigate(location);
	};

    /* --- Add errors class to form (useful to animate) --- */
	const addErrorsClass = () => {
		const form = document.querySelector(".forms form");
		form.classList.add("errors");
		setTimeout(() => {
			form.classList.remove("errors");
		}, 500);
	};

    return (
        <section className="login-component forms">
            {user.isLogged && <Navigate to="/" />}
            <h1>
                {langs.text.account.logInTitle[langs.lang]}
            </h1>
            <p className="after-title">
                {langs.text.account.or[langs.lang]}
                <Link to="/register">{langs.text.account.createAccount[langs.lang]}</Link>
            </p>
            <form onSubmit={submitForm}>
                <input
                    type="email"
                    placeholder={langs.text.account.email[langs.lang]}
                    onChange={e => setLoginEmail(e.target.value)} />
                <input
                    type="password"
                    placeholder={langs.text.account.password[langs.lang]}
                    onChange={e => setLoginPassword(e.target.value)} />
                <div className="forms-link">
                    <Link to="/recover">{langs.text.account.forgotPasswordQuestion[langs.lang]}</Link>
                </div>
                {!isLoading && 
                    <button
                        onClick={login}
                        className={"button" + (loginEmail && loginPassword && " bg-color-1")}>
                            {langs.text.account.logIn[langs.lang]}
                    </button>
                }
                {isLoading && <Loading classes="button-loading" />}
                <p className="forms-error">{loginResult}</p>
            </form>
		</section>
    );
}