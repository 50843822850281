import { useContext } from "react";
import { LangContext } from "./App.js";
import { NavLink } from "react-router-dom";
import ProgressBar from "./product-components/ProgressBar";

export default function PathHeader({
    actualPathName,
    userPaths,
    progress,
    basePath,
    backURL,
    backText,
    subtitle
}) {

    // languages
    const {langs} = useContext(LangContext);
	
	// component
	return (
        <header className="path-header-component">
            <h2>{subtitle}</h2>
            <div className="learning-paths-selector">
                <h1 className="triangle">
                    {actualPathName}
                </h1>
                <div className="learning-paths-select">
                    {
                        userPaths.map((pathLink, pathIndex) => {
                            return (
                                <NavLink
                                    to={`/${basePath}/${pathLink.url}`}
                                    key={pathIndex}
                                >
                                    {pathLink.name[langs.lang]}
                                </NavLink>
                            )
                        })
                    }
                </div>
            </div>
            <ProgressBar from="0" to={progress} classes="big-progress" />
            <NavLink to={backURL} className="path-header-back button bg-color-1">
                {backText}
            </NavLink>
        </header>
	);
}