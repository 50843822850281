import { useContext } from "react";
import Logo from "./Logo";
import LanguagesSelector from "./LanguagesSelector";
import Menu from "./Menu";
import LoginUserButton from "./LoginUserButton";
import { UserContext } from "./App.js";

export default function Header() {

    /* --- user context --- */
    const {user} = useContext(UserContext);
	
	// component
	return (
        <header className="header-component">
            <div className="header-component-container">
                <div className="header-component-left">
                    <Logo />
                    {user.isLogged && <Menu />}
                </div>
                <div className="header-component-right">
                    <LanguagesSelector />
                    <LoginUserButton />
                </div>
            </div>
        </header>
	);
}