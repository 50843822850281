import { useContext, useState, useEffect } from "react";
import { LangContext } from "./App.js";
import { useNavigate } from "react-router-dom";
import Loading from "./Loading";
import Axios from "axios";
import TagManager from 'react-gtm-module';

export default function RecoverPassword() {

    /* --- lang context --- */
    const {langs} = useContext(LangContext);

    /* --- redirects --- */
	const navigate = useNavigate();

    /* --- states for fields --- */
    const [recoverEmail, setRecoverEmail] = useState("");
	const [recoverResult, setRecoverResult] = useState("");
	const [isLoading, setIsLoading] = useState(false);

	/* --- data layer tag manager --- */
	useEffect(() => {
        /* --- data layer --- */
        const tagManagerArgs = {
            dataLayer: {
                event: 'pageview',
                page: window.location.href
            }
        }
        TagManager.dataLayer(tagManagerArgs);
    }, []);

    /* --- Recover API --- */
    const recover = () => {
		setIsLoading(true);
		Axios({
			method: "POST",
			data: {
				email: recoverEmail,
				lang: langs.lang
			},
			withCredentials: true,
			url: `${process.env.REACT_APP_SERVER_URL}/recover`,

        // email sent
		}).then((res) => {
			redirect("/reset");

        // error
		}).catch((error) => {
			addErrorsClass();
			setRecoverResult(langs.text.account.recoverError[langs.lang]);
			setIsLoading(false);
		});
	};

	/* --- every time the language is changed --- */
    useEffect(() => {
        // errors do not translate, this sets them empty
        setRecoverResult("");
    }, [langs.lang]);

    /* --- prevent form submit --- */
    const submitForm = (e) => {
        e.preventDefault();
    };

    /* --- navigate --- */
	const redirect = (location) => {
		navigate(location);
	};

    /* --- Add errors class to form (useful to animate) --- */
	const addErrorsClass = () => {
		const form = document.querySelector(".forms form");
		form.classList.add("errors");
		setTimeout(() => {
			form.classList.remove("errors");
		}, 500);
	};

    return (
        <div className="recover-password-component forms">
            <h1>
				{langs.text.account.recoverPasswordTitle[langs.lang]}
			</h1>
            <p className="primary-color after-title">
				{langs.text.account.recoverPasswordDescription[langs.lang]}
			</p>
            <form onSubmit={submitForm}>
                <input 
					type="email"
					placeholder={langs.text.account.email[langs.lang]}
					onChange={e => setRecoverEmail(e.target.value)} />
				{!isLoading && 
					<button
						onClick={recover}
						className={"button" + (recoverEmail && " bg-color-1")}>
							{langs.text.account.recoverPasswordTitle[langs.lang]}
					</button>
				}
				{isLoading && <Loading classes="button-loading" />}
                <p className="forms-error">{recoverResult}</p>
            </form>
        </div>
    );
}