import { useContext, useEffect, useState, useCallback, useRef } from "react";
import { LangContext } from "./App.js";
import PathHeader from "./PathHeader";
import ProductLink from "./ProductLink";
import Loading from "./Loading";
import ScrollContainer from "react-indiana-drag-scroll";
import Axios from "axios";
import Error from "./Error.js";
import { useParams } from "react-router-dom";
import TagManager from 'react-gtm-module';

export default function LearningPaths() {

    /* --- vars --- */
    const {langs} = useContext(LangContext);
    const [userPaths, setUserPaths] = useState([]);
    const [path, setPath] = useState();
    const [showError, setShowError] = useState(false);
    const [actualPathName, setActualPathName] = useState("");
    const {pathURL} = useParams();
    const [progress, setProgress] = useState(0);
    const [segments, setSegments] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [resultPath, setResultPath] = useState("");
    const isUnavailable = useRef(false);

    /* --- data layer tag manager --- */
    useEffect(() => {
        /* --- data layer --- */
        const tagManagerArgs = {
            dataLayer: {
                event: 'pageview',
                page: window.location.href
            }
        }
        TagManager.dataLayer(tagManagerArgs);
    }, []);

    /* --- API: get user paths --- */
    const getUserPaths = useCallback(async () => {
        setIsLoading(true);
        Axios({
            method: "GET",
            withCredentials: true,
            url: `${process.env.REACT_APP_SERVER_URL}/paths`,

        // get user paths success
        }).then((res) => {
            if (res.status === 200 && res.data.result.length) {
                setUserPaths(res.data.result);
                if (pathURL) {
                    getPath(pathURL);
                    setResultPath(pathURL);
                } else {
                    getPath(
                        res.data.result[0].url
                    );
                    setResultPath(res.data.result[0].url);
                }
            } else {
                setIsLoading(false);
                setShowError(true);
            }

        // error getting user paths
        }).catch((err) => {
            setIsLoading(false);
            setShowError(true);
        });
    }, [pathURL]);

    /* --- API: get path --- */
    const getPath = async (pathURL) => {
        Axios({
            method: "GET",
            withCredentials: true,
            url: `${process.env.REACT_APP_SERVER_URL}/paths/${pathURL}`,

        // get user paths success
        }).then((res) => {
            if (res.status === 200) {
                setPath(res.data.result);
            } else {
                setIsLoading(false);
                setShowError(true);
            }

        // error getting user paths
        }).catch((err) => {
            setIsLoading(false);
            setShowError(true);
        });
    };

    /* --- initial effect --- */
    useEffect(() => {
        getUserPaths();
    }, [getUserPaths]);

    /* --- set path name --- */
    useEffect(() => {
        if (path) {
            setActualPathName(path.name[langs.lang]);
            setProgress(path.userProgress);
            setSegments(path.segments);
            setIsLoading(false);
        }
    }, [langs.lang, path]);

    /* --- create segments --- */
    const getMySegments = () => {
        // segments loop
        return segments.map((segment, segmentIndex) => {
            if (segmentIndex === 0) isUnavailable.current = false;
            return (
                <div className="learning-path-segment" key={segmentIndex}>
                    <h5>{segment.title[langs.lang]}</h5>
                    <div className="learning-path-segment-content">
                        {
                            getSegmentProducts(segment)
                        }
                    </div>
                </div>
            );
        });
    };

    /* --- get segment products --- */
    const getSegmentProducts = (aSegment) => {
        // segment products loop
        return aSegment.products.map((product, productIndex) => {
            // product link
            let productLink = `${product.productURL}?path=${path.url}`;
            if (product.productParams) {
                productLink += `&${product.productParams}`;
            }
            // completed and unavailable classes
            let classes = "available";
            if (product.completed) classes = "completed";
            if (isUnavailable.current) classes = "unavailable";
            if (!product.completed) isUnavailable.current = true;
            // render
            return (
                <ProductLink
                    link={productLink}
                    text={product.productName}
                    classes={classes}
                    key={productIndex}
                />
            )
        });
    };

    /* --- render --- */
    return (
        <div className="learning-paths-component">
            {/* --- Learning path header --- */}
            <PathHeader
                actualPathName={actualPathName}
                userPaths={userPaths}
                progress={progress}
                basePath="paths"
                backURL={`/results/${resultPath}`}
                backText={langs.text.learningPath.shortBackToResults[langs.lang]}
                subtitle={langs.text.learningPath.learningPathName[langs.lang]}
            />
            {/* --- segments and products --- */}
            <ScrollContainer className="learning-paths-path" hideScrollbars="false">
                {getMySegments()}
            </ScrollContainer>
            {/* --- Error screen --- */}
            {showError && <Error />}
            {/* --- Loading screen --- */}
            {isLoading && <Loading classes="loading-absolute" />}
        </div>
    );
}