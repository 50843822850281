const LANGUAGES = {
    languages: {
      	en: {
			en: "English",
			es: "English"
      	},
      	es: {
			en: "Español",
			es: "Español"
      	}
    },
	generic: {
		loremIpsum: {
			en: "Lorem ipsum dolor sit english.",
			es: "Lorem ipsum dolor sit español."
		},
		genericFormError: {
			en: "An error has occurred. Please check the input fields and try again.",
			es: "Ocurrió un error. Por favor revisa los campos e intenta nuevamente."
		},
		pageNotFoundTitle: {
			en: "Page not found",
			es: "Página no encontrada"
		},
		pageNotFoundDescription: {
			en: "This page does not exist or is no longer available.",
			es: "Este enlace no existe o ya no está disponible."
		},
		next: {
			en: "Next",
			es: "Siguiente"
      	},
		back: {
			en: "Back",
			es: "Volver"
      	},
		start: {
			en: "Start",
			es: "Empezar"
      	},
		finish: {
			en: "Finish",
			es: "Terminar"
      	},
		completed: {
			en: "Completed",
			es: "Completado"
		},
		needHelp: {
			en: "Need help?",
			es: "¿Necesitas ayuda?"
		},
		errorTitle: {
			en: "Sorry, an error ocurred",
			es: "Lo sentimos, ocurrió un error"
		},
		errorDescription: {
			en: "Try reloading the page, or contact us if the problem persists.",
			es: "Prueba recargando la página o contáctanos si el problema persiste."
		},
		reload: {
			en: "Reload page",
			es: "Recargar página"
		},
		noCookiesTitle: {
			en: "Please enable cookies",
			es: "Por favor activa las cookies."
		},
		noCookiesParagraph: {
			en: "This app needs cookies. Please enable the cookie jar and then reaload the page.",
			es: "Esta app necesita cookies. Por favor actívalas y luego recarga la página."
		},
		chooseAnOption: {
			en: "Choose an option",
			es: "Selecciona una opción"
		}
	},
    account: {
		or: {
			en: "or ",
			es: "o "
		},
		logIn: {
			en: "Sign in",
			es: "Entrar"
		},
		logInTitle: {
			en: "Sign in to StartIQ",
			es: "Ingresa en StartIQ"
		},
		logOut: {
			en: "Log out",
			es: "Salir"
		},
		createAccount: {
			en: "Create your account",
			es: "Crea tu cuenta"
		},
		name: {
			en: "Name",
			es: "Nombre"
        },
        lastname: {
			en: "Last Name",
			es: "Apellido"
        },
        email: {
			en: "Email address",
			es: "Correo"
        },
        password: {
			en: "Password",
			es: "Contraseña"
		},
		forgotPasswordQuestion: {
			en: "Forgot your password?",
			es: "¿Olvidaste tu contraseña?"
		},
        groupCode: {
			en: "Group code (optional)",
			es: "Código de grupo (opcional)"
        },
        legal: {
			en: "By clicking Sign up, you agree to our <a href='https://www.startiq.org' target='_blank'>Privacy Policy</a> and <a href='https://www.startiq.org' target='_blank'>Cookies Policy</a>",
			es: "Al hacer clic en Registrarse, aceptas nuestra <a href='https://www.startiq.org' target='_blank'>Política de Privacidad</a> y la <a href='https://www.startiq.org' target='_blank'>Política de Cookies</a>"
        },
        signUp: {
			en: "Sign Up",
			es: "Registro"
        },
        legalAccept: {
          	en: "By clicking Sign up, you agree to our Privacy Policy and Cookies Policy",
          	es: "Acepto la politica de privacidad y la política de cookies"
        },
		nameError: {
			en: "Invalid name.",
			es: "Nombre inválido."
		},
		emailError: {
			en: "Invalid email address.",
			es: "Dirección de correo inválida."
		},
		oldEmailError: {
			en: "The actual email address is incorrect.",
			es: "El correo actual es incorrecto."
		},
		passwordError: {
			en: "Invalid password.",
			es: "Contraseña inválida."
		},
		oldPasswordError: {
			en: "Invalid password.",
			es: "Contraseña inválida."
		},
		invalidPasswordError: {
			en: "The password must have at least 6 characters.",
			es: "La contraseña debe tener al menos 6 caracteres."
		},
		groupError: {
			en: "Invalid group code.",
			es: "Código de grupo no válido."
		},
		codeError: {
			en: "Invalid code.",
			es: "Código no válido."
		},
		legalError: {
			en: "To register, please accept the legal notice.",
			es: "Para completar el registro, por favor acepta el aviso legal."
		},
		logInError: {
			en: "Incorrect email or password.",
			es: "Correo o contraseña incorrectos."
		},
		registeredError: {
			en: "The email address is already registered.",
			es: "El correo electrónico se encuentra registrado."
		},
		databaseError: {
			en: "There was a problem, please try again.",
			es: "Ocurrió un problema, por favor intenta nuevamente."
		},
		langError: {
			en: "There was a problem with the language, please try again.",
			es: "Ocurrió un problema con el idioma, por favor intenta nuevamente."
		},
		formatError: {
			en: "There was a problem, please try again.",
			es: "Ocurrió un problema, por favor intenta nuevamente."
		},
		recoverPasswordTitle: {
			en: "Recover password",
			es: "Recuperar contraseña"
		},
		recoverPasswordDescription: {
			en: "Enter your email address below to recover your password",
			es: "Ingresa tu correo para recuperar tu contraseña"
		},
		resetPasswordDescription: {
			en: "Enter the code we sent to your email to create a new password",
			es: "Ingresa el código que enviamos a tu correo para reestablecer la contraseña"
		},
		resetCode: {
			en: "Received code",
			es: "Código recibido"
		},
		newPassword: {
			en: "New password",
			es: "Nueva contraseña"
		},
		savePassword: {
			en: "Save password",
			es: "Guardar contraseña"
		},
		recoverError: {
			en: "Please verify the email address and try again.",
			es: "Por favor verifica el correo e intenta nuevamente."
		}
    },
	learningPath: {
		pathMenuName: {
			en: "Learning paths",
			es: "Mis sendas de aprendizaje"
		},
		myLearningPath: {
			en: "My learning path",
			es: "Mi senda de aprendizaje"
		},
		returnLearningPath: {
			en: "Return to my learning path",
			es: "Volver a mi senda de aprendizaje"
		},
		starImageAlt: {
			en: "StartIQ star",
			es: "Estrella StartIQ"
		},
		learningPathName: {
			en: "Learning path",
			es: "Senda de aprendizaje"
		},
		shortBackToPath: {
			en: "Back to path",
			es: "Volver a senda"
		},
		shortBackToResults: {
			en: "See results",
			es: "Ver resultados"
		},
		viewMyResults: {
			en: "View my results",
			es: "Ver mis resultados"
		},
		startProduct: {
			en: "Start",
			es: "Empezar"
		},
		completedProduct: {
			en: "Completed",
			es: "Terminado"
		}
	},
	results: {
		resultsMenuName: {
			en: "My results",
			es: "Mis resultados"
		},
		resultTitle: {
			en: "Total Score",
			es: "Puntaje Total"
		},
		score: {
			en: "Score",
			es: "Score"
		},
		points: {
			en: "pts",
			es: "pts"
		},
		feedback: {
			en: "Feedback",
			es: "Resultado"
		},
		average: {
			en: "On track",
			es: "En camino"
		},
		above: {
			en: "Exceptional",
			es: "Excepcional"
		},
		below: {
			en: "Needs work",
			es: "A mejorar"
		},
		unfinished: {
			en: "Incomplete",
			es: "Sin terminar"
		},
		unfinishedFeedback: {
			en: "What are you waiting for? Complete this product to know your result.",
			es: "¿Qué esperas? Completa este producto para conocer tu resultado."
		},
		ideaEvaluationTitle: {
			en: "Idea Evaluation Ability",
			es: "Habilidad Evaluando Ideas"
		},
		entrepreneurialSkills: {
			en: "Entrepreneurial Implementation Skills",
			es: "Habilidad de Implementación Empresarial"
		},
		tableProduct: {
			en: "Product",
			es: "Producto"
		}
	}
}

export default LANGUAGES;