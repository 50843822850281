import { useContext, useState, useEffect } from "react";
import { LangContext } from "./App.js";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import Loading from "./Loading";
import TagManager from 'react-gtm-module';

export default function ResetPassword() {

    /* --- lang context --- */
    const {langs} = useContext(LangContext);

    /* --- redirects --- */
	const navigate = useNavigate();

    /* --- states for fields --- */
    const [resetEmail, setResetEmail] = useState("");
	const [resetPassword, setResetPassword] = useState("");
	const [resetCode, setResetCode] = useState("");
	const [resetResult, setResetResult] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    /* --- data layer tag manager --- */
    useEffect(() => {
        /* --- data layer --- */
        const tagManagerArgs = {
            dataLayer: {
                event: 'pageview',
                page: window.location.href
            }
        }
        TagManager.dataLayer(tagManagerArgs);
    }, []);

    /* --- Reset API --- */
    const reset = () => {

        // loading screen
		setIsLoading(true);

	    Axios({
            method: "PATCH",
            data: {
                email: resetEmail,
                password: resetPassword,
                lang: "en",
                code: resetCode
            },
            withCredentials: true,
            url: `${process.env.REACT_APP_SERVER_URL}/recover`,

        // password changed
        }).then((res) => {
            redirect("/login");

        // errors
        }).catch((error) => {
            // display errors
			addErrorsClass();
			if(error.response?.data?.errors?.length > 0) {
				setResetResult(
					error.response.data.errors.map(er => {
						return langs.text.account[`${er}Error`][langs.lang] + " "
					})
				);
			} else {
				setResetResult(
					langs.text.generic.genericFormError[langs.lang]
				);
			}
            setIsLoading(false);
        });
	};

    /* --- every time the language is changed --- */
    useEffect(() => {
        // errors do not translate, this sets them empty
        setResetResult("");
    }, [langs.lang]);

    /* --- prevent form submit --- */
    const submitForm = (e) => {
        e.preventDefault();
    };

    /* --- navigate --- */
	const redirect = (location) => {
		navigate(location);
	};

    /* --- Add errors class to form (useful to animate) --- */
	const addErrorsClass = () => {
		const form = document.querySelector(".forms form");
		form.classList.add("errors");
		setTimeout(() => {
			form.classList.remove("errors");
		}, 500);
	};

    return (
        <div className="recover-password-component forms">
            <h1>
                {langs.text.account.recoverPasswordTitle[langs.lang]}
            </h1>
            <p className="primary-color after-title">
                {langs.text.account.resetPasswordDescription[langs.lang]}
            </p>
            <form onSubmit={submitForm} autoComplete="off">
                <input
                    type="email"
                    placeholder={langs.text.account.email[langs.lang]}
                    onChange={e => setResetEmail(e.target.value)} />
                <input
                    type="text"
                    placeholder={langs.text.account.resetCode[langs.lang]}
                    onChange={e => setResetCode(e.target.value)} />
			    <input 
                    type="password"
                    placeholder={langs.text.account.newPassword[langs.lang]}
                    onChange={e => setResetPassword(e.target.value)} />
                {!isLoading && 
                    <button
                        onClick={reset}
                        className={
                            "button" +
                            (resetEmail && resetPassword && resetCode && " bg-color-1")
                            }>
                            {langs.text.account.savePassword[langs.lang]}
                    </button>
                }
                {isLoading && <Loading classes="button-loading" />}
                <p className="forms-error">{resetResult}</p>
            </form>
        </div>
    );
}