import { useContext } from "react";
import { LangContext } from "./App.js";
import { NavLink } from "react-router-dom";
import checkmark from "../images/check.svg";

export default function ProductLink({text = "", link = "", classes = ""}) {

    const {langs} = useContext(LangContext);

    return (
        <NavLink className={"product-link-component " + classes} to={link}>
            <h6>{text[langs.lang]}</h6>
            <div className="product-link-start">
                <span>{langs.text.learningPath.startProduct[langs.lang]}</span>
                <div className="product-link-icon">
                    <svg width="18" height="9" viewBox="0 0 18 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.1667 1.16675L16.5 4.50008M16.5 4.50008L13.1667 7.83341M16.5 4.50008L1.5 4.50008" stroke="#EC5323" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </div> 
            </div>
            <div className="product-link-completed">
                <span>{langs.text.learningPath.completedProduct[langs.lang]}</span>
                <div className="product-link-icon">
                    <img src={checkmark} alt={langs.text.learningPath.completedProduct[langs.lang]} />
                </div> 
            </div>
        </NavLink>
    );
}