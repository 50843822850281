import { useContext, useEffect, useState, useCallback, useRef } from "react";
import { useParams } from "react-router-dom";
import { LangContext } from "./App.js";
import PathHeader from "./PathHeader";
import Loading from "./Loading";
import Error from "./Error.js";
import Axios from "axios";
import help from "../images/help.svg";
import TagManager from 'react-gtm-module';
import ScrollContainer from "react-indiana-drag-scroll";

export default function Results() {

    /* --- languages --- */
    const {langs} = useContext(LangContext);

    /* --- route --- */
    const {pathURL} = useParams();

    /* --- vars --- */
    const [userPaths, setUserPaths] = useState([]);
    const [path, setPath] = useState();
    const [resultPath, setResultPath] = useState("");
    const [actualPathName, setActualPathName] = useState("");
    const [showError, setShowError] = useState(false);
    const [progress, setProgress] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const products = useRef({
        idea: [],
        skill: []
    });
    const levelBoundaries = [0, 4, 5, 7, 8, 10];
    const ideaAverage = useRef([]);
    const skillAverage = useRef([]);
    const totalAverage = useRef([]);
    const totalType = useRef("unfinished");

    /* --- data layer tag manager --- */
    useEffect(() => {
        /* --- data layer --- */
        const tagManagerArgs = {
            dataLayer: {
                event: 'pageview',
                page: window.location.href
            }
        }
        TagManager.dataLayer(tagManagerArgs);
    }, []);

    /* --- text strings --- */
    const pointsText = langs.text.results.points[langs.lang];
    const fbText = langs.text.results.feedback[langs.lang];
    const belowText = langs.text.results.below[langs.lang];
    const averageText = langs.text.results.average[langs.lang];
    const aboveText = langs.text.results.above[langs.lang];
    const unfinishedText = langs.text.results.unfinished[langs.lang];
    const productText = langs.text.results.tableProduct[langs.lang];
    const scoreText = langs.text.results.score[langs.lang];


    /* --- API: get user paths --- */
    const getUserPaths = useCallback(async () => {
        setIsLoading(true);
        Axios({
            method: "GET",
            withCredentials: true,
            url: `${process.env.REACT_APP_SERVER_URL}/paths`,

        // get user paths success
        }).then((res) => {

            // OK
            if (res.status === 200 && res.data.result.length) {

                // paths select
                setUserPaths(res.data.result);

                // path in URL
                if (pathURL) {
                    getPath(pathURL);
                    setResultPath(pathURL);
                
                // show first path
                } else {
                    getPath(
                        res.data.result[0].url
                    );
                    setResultPath(res.data.result[0].url);
                }

            // error getting user paths
            } else {
                setIsLoading(false);
                setShowError(true);
            }

        // error getting user paths
        }).catch((err) => {
            setIsLoading(false);
            setShowError(true);
        });
    }, [pathURL]);


    /* --- API: get path --- */
    const getPath = async (pathURL) => {
        Axios({
            method: "GET",
            withCredentials: true,
            url: `${process.env.REACT_APP_SERVER_URL}/paths/${pathURL}`,

        // get user paths success
        }).then((res) => {

            // got the path, now set it up
            if (res.status === 200) {
                setPath(res.data.result);

            // error
            } else {
                setIsLoading(false);
                setShowError(true);
            }

        // error getting user paths
        }).catch((err) => {
            setIsLoading(false);
            setShowError(true);
        });
    };


    /* --- initial effect --- */
    useEffect(() => {
        getUserPaths();
    }, [getUserPaths]);


    /* --- every time we get a path --- */
    useEffect(() => {
        if (path) {

            // header details
            setActualPathName(path.name[langs.lang]);
            setProgress(path.userProgress);
            setIsLoading(false);

            // create products results array
            if (path.segments?.length) {
                products.current = {
                    idea: [],
                    skill: []
                };
                totalAverage.current = [];
                ideaAverage.current = [];
                skillAverage.current = [];

                // loop segments
                for (let segment of path.segments) {

                    // loop segment products
                    for (let theProduct of segment.products) {

                        // get only products with scoreType
                        if (theProduct.hasOwnProperty("scoreType")) {

                            // idea results
                            if (theProduct.scoreType === "idea") {
                                products.current.idea.push(theProduct);
                                // if the product has a score
                                if (theProduct.hasOwnProperty("friendlyScore")) {
                                    ideaAverage.current.push(theProduct.friendlyScore);
                                    totalAverage.current.push(theProduct.friendlyScore);
                                }

                            // skill result
                            } else {
                                products.current.skill.push(theProduct);
                                // if the product has a score
                                if (theProduct.hasOwnProperty("friendlyScore")) {
                                    skillAverage.current.push(theProduct.friendlyScore);
                                    totalAverage.current.push(theProduct.friendlyScore);
                                }
                            }
                        }
                    }
                }
            }

        }
    }, [langs.lang, path]);


    /* --- total score --- */
    const getTotalScore = () => {
        let totalScore = "-";
        totalType.current = "unfinished";
        const totalProducts = products.current.idea.length + products.current.skill.length;
        const totalAvLength = totalAverage.current.length;
        if (totalAvLength === totalProducts && totalProducts) {
            const sum = totalAverage.current.reduce((a, b) => a + b, 0);
            totalScore = Math.round(sum / totalAverage.current.length);
        }
        if (totalScore >= levelBoundaries[0]) {
            totalType.current = "below";
        }
        if (totalScore >= levelBoundaries[2]) {
            totalType.current = "average";
        }
        if (totalScore >= levelBoundaries[4]) {
            totalType.current = "above";
        }
        if (totalScore !== "-") {
            totalScore += pointsText + ".";
        }
        return <span className="total-score-value">{totalScore}</span>;
    };


    /* --- total feedback --- */
    const getTotalFB = () => {

        let finalTotalFB = unfinishedText;
        if(totalType.current === "below") {
            finalTotalFB = belowText;
        }
        if(totalType.current === "average") {
            finalTotalFB = averageText;
        }
        if(totalType.current === "above") {
            finalTotalFB = aboveText;
        }

        // render
        return (
            <div className="total-score-fb">
                <div className={"score-fb-tag " + totalType.current}>{finalTotalFB}</div>
                <img src={help} alt="StartIQ" />
                <div className="total-score-info">
                    <h6>{fbText}</h6>
                    <p>
                        <span className="score-fb-tag above">{aboveText}</span>
                        {levelBoundaries[4] + "-" + levelBoundaries[5]} {pointsText}
                    </p>
                    <p>
                        <span className="score-fb-tag average">{averageText}</span>
                        {levelBoundaries[2] + "-" + levelBoundaries[3]} {pointsText}
                    </p>
                    <p>
                        <span className="score-fb-tag below">{belowText}</span>
                        {levelBoundaries[0] + "-" + levelBoundaries[1]} {pointsText}
                    </p>
                </div>
            </div>
        );
    };

    /* --- idea and skills table results --- */

    const getTableScore = (tableType) => {

        // text
        let tableText;
        if (tableType === "idea") {
            tableText = langs.text.results.ideaEvaluationTitle[langs.lang];
        } else {
            tableText = langs.text.results.entrepreneurialSkills[langs.lang];
        }

        // compute total table average
        let sectionAverage = "-";
        let sectionType = "unfinished";
        // idea average
        const ideaAvLength = ideaAverage.current.length;
        const prodIdeaLength = products.current.idea.length;
        if (tableType === "idea" && ideaAvLength === prodIdeaLength && prodIdeaLength) {
            const sum = ideaAverage.current.reduce((a, b) => a + b, 0);
            sectionAverage = Math.round(sum / ideaAverage.current.length);
        }
        // skill average
        const skillAvLength = skillAverage.current.length;
        const prodSkillLength = products.current.skill.length;
        if (tableType === "skill" && skillAvLength === prodSkillLength && prodSkillLength) {
            const sum = skillAverage.current.reduce((a, b) => a + b, 0);
            sectionAverage = Math.round(sum / skillAverage.current.length);
        }
        let sectionState = unfinishedText;
        if (sectionAverage !== "-") {
            if (sectionAverage >= levelBoundaries[0]) {
                sectionType = "below";
                sectionState = belowText;
            }
            if (sectionAverage >= levelBoundaries[2]) {
                sectionType = "average";
                sectionState = averageText;
            }
            if (sectionAverage >= levelBoundaries[4]) {
                sectionType = "above";
                sectionState = aboveText;
            }
            sectionAverage += pointsText;
        }

        //render
        return (
            <ScrollContainer className="result-table" hideScrollbars="false">

                {/* Header */}
                <div className="result-table-header">
                    <h3>{tableText}:</h3>
                    <h4>{sectionAverage}</h4>
                    <div className={"score-fb-tag " + sectionType}>{sectionState}</div>
                </div>

                {/* Table */}
                <table>
                    <thead>
                        <tr>
                            <th>{productText}</th>
                            <th>{scoreText}</th>
                            <th>{fbText}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            productsLoop(tableType)
                        }
                    </tbody>
                </table>
                
            </ScrollContainer>
        );
    };

    /* --- products loop --- */
    const productsLoop = tableType => {

        // this array will be returned
        let productsResult = [];
        for (let i = 0;i < products.current[tableType].length;i++) {

            const prodContent = products.current[tableType][i];
            let friendlyScore = Math.round(prodContent.friendlyScore);
            let levelState = "unfinished";
            let levelStateText = unfinishedText;
            let prodClasses = "result-prod-row";
            let prodFBText = langs.text.results.unfinishedFeedback[langs.lang];
            if (!isNaN(friendlyScore) && prodContent.resultFeedback) {
                if (friendlyScore >= levelBoundaries[0]) {
                    prodFBText = prodContent.resultFeedback.below[langs.lang];
                }
                if (friendlyScore >= levelBoundaries[2]) {
                    prodFBText = prodContent.resultFeedback.average[langs.lang];
                }
                if (friendlyScore >= levelBoundaries[4]) {
                    prodFBText = prodContent.resultFeedback.above[langs.lang];
                }
            }
            if (isNaN(friendlyScore)) {
                friendlyScore = "-";
            } else {
                if (friendlyScore >= levelBoundaries[0]) {
                    levelState = "below";
                    levelStateText = belowText;
                }
                if (friendlyScore >= levelBoundaries[2]) {
                    levelState = "average";
                    levelStateText = averageText;
                }
                if (friendlyScore >= levelBoundaries[4]) {
                    levelState = "above";
                    levelStateText = aboveText;
                }
                prodClasses += " result-prod-row-button";
                friendlyScore += pointsText;
            }
            productsResult.push(
                <tr
                    key={i}
                    className={prodClasses}
                >
                    <td className="prod-first-col">{prodContent.productName[langs.lang]}</td>
                    <td className="prod-second-col">
                        {friendlyScore}
                    </td>
                    <td className="prod-third-col">
                        <div className={"score-fb-tag " + levelState}>{levelStateText}</div>
                        <p>{prodFBText}</p>
                    </td>
                </tr>
            );
        }
        return productsResult;
    };

    /* --- render --- */
    return (
        <div className="learning-paths-component results-component">
            {/* --- Learning path header --- */}
            <PathHeader
                actualPathName={actualPathName}
                userPaths={userPaths}
                progress={progress}
                basePath="results"
                backURL={`/paths/${resultPath}`}
                backText={langs.text.learningPath.shortBackToPath[langs.lang]}
                subtitle={langs.text.results.resultsMenuName[langs.lang]}
            />
            {/* --- total average result --- */}
            <div className="results-header">
                <h2 className="results-component-title">
                    <span className="total-score-title">{langs.text.results.resultTitle[langs.lang]}:</span>
                    {getTotalScore()}
                </h2>
                {getTotalFB()}
            </div>
            {/* --- product results --- */}
            <div className="results-container">
                {getTableScore("idea")}
                {getTableScore("skill")}
            </div>
            {/* --- Error screen --- */}
            {showError && <Error />}
            {/* --- Loading screen --- */}
            {isLoading && <Loading classes="loading-absolute" />}
        </div>
    );
}