import React, { useContext, useState, useEffect, useRef, Suspense, lazy } from "react";
import { LangContext } from "./App.js";
import Error from "./Error.js";
import Loading from "./Loading";
import { useNavigate, useParams, useSearchParams, NavLink } from "react-router-dom";
import Axios from "axios";
import TagManager from 'react-gtm-module';


/* --- lazy loading components --- */
const lazyImport = file =>
  lazy(() =>
    import(`./product-components/${file}`)
);


export default function Product() {


    /* --- router --- */
    const navigate = useNavigate();
    const {id} = useParams();
    const [linkParams, setLinkParams] = useSearchParams();
    const myParams = useRef({});


    /* --- lang context --- */
    const {langs} = useContext(LangContext);


    /* --- states --- */
    // breadcrumbs
    const [showBreadcrumbs, setShowBreadcrumbs] = useState(false);
    const [breadcrumbName, setBreadcrumbName] = useState("");
    const [breadcrumbSegment, setBreadcrumbSegment] = useState("");
    // header buttons
    const [showBackBtn, setShowBackBtn] = useState(false);
    const [showNextBtn, setShowNextBtn] = useState(false);
    const [showStartBtn, setShowStartBtn] = useState(false);
    const [showFinishBtn, setShowFinishBtn] = useState(false);
    const [showFakeBtn, setShowFakeBtn] = useState(false);
    const [forceNavButtons, setForceNavButtons] = useState(false);
    const [fakeNextClicked, setFakeNextClicked] = useState(false);
    // product
    const [product, setProduct] = useState();
    const [section, setSection] = useState({pages: []});
    const [sectionIndex, setSectionIndex] = useState(0);
    const [showSection, setShowSection] = useState(false);
    const [pageNum, setPageNum] = useState(1);
    const [updateAnswerData, setUpdateAnswerData] = useState();
    const savedAnswer = useRef();
    const saveExtras = useRef();
    const seeResults = useRef(false);
    // error component
    const [showError, setShowError] = useState(false);
    const [errorText, setErrorText] = useState({
        title: "",
        paragraph: ""
    });
    // loading
    const [isLoading, setIsLoading] = useState(true);


    /* --- Effects --- */


    /* --- data layer tag manager --- */
    useEffect(() => {
        /* --- data layer --- */
        const tagManagerArgs = {
            dataLayer: {
                event: 'pageview',
                page: window.location.href
            }
        }
        TagManager.dataLayer(tagManagerArgs);
    }, []);

    /* --- save URL params with useRef for easy access --- */
    useEffect(() => {
        // reset saved params
        myParams.current = {};
        // get URL params
        for (const linkParam of linkParams.entries()) {
            const [key, value] = linkParam;
            myParams.current[key] = value;
        }
    }, [linkParams]);

    /* --- if errors stop loading --- */
    useEffect(() => {
        if (showError) {
            setIsLoading(false);
        }
    }, [showError]);


    /* --- Initial effect: call product API --- */
    useEffect(() => {

        // loading
        setIsLoading(true);

        // check if user has cookies enabled
        if (!navigator.cookieEnabled) {
            setErrorText({
                title: langs.text.generic.noCookiesTitle[langs.lang],
                paragraph: langs.text.generic.noCookiesParagraph[langs.lang]
            });
            setShowError(true);
            return;
        }

        // prevent double fetch on development caused by StrictMode
        let ignore = false;

        // set up page, section, and params
        const haveSameData = (storedParams) => {
            const urlParamsLength = Object.keys(myParams.current).length;
            const storedParamsLength = Object.keys(storedParams).length;
            if (urlParamsLength === storedParamsLength) {
                return Object.keys(myParams.current).every(
                    key => storedParams.hasOwnProperty(key)
                        && storedParams[key] === myParams.current[key]);
            }
            return false;
        }
        const setUpProductForUser = (currentSection, currentPage, savedURLParams) => {
            setSectionIndex(currentSection);
            setPageNum(currentPage);
            if (!savedURLParams && Object.keys(myParams.current).length) {
                setLinkParams();
                return;
            }
            if(!savedURLParams) savedURLParams = {};
            if (!haveSameData(savedURLParams))
                setLinkParams(savedURLParams);
        };

        // API accepts path param to return data for breadcrumbs
        let apiParams = {};
        if (myParams.current.path) apiParams.path = myParams.current.path;
        
        // API: get the product
        Axios({
			method: "GET",
			withCredentials: true,
			url: `${process.env.REACT_APP_SERVER_URL}/products/${id}`,
            timestamp: new Date(),
            params: apiParams

        // product found
		}).then((res) => {

            // prevent double fetch on development caused by StrictMode
            if (!ignore) {
                
                // product found
                if (res.status === 200) {

                    setProduct(res.data.product);

                    // data layer
                    const tagManagerArgs = {
                        dataLayer: {
                            event: "productId",
                            productId: res.data.product._id
                        }
                    }
                    TagManager.dataLayer(tagManagerArgs);
    
                    // get previous answer or init one
                    Axios({
                        method: "GET",
                        withCredentials: true,
                        url: `${process.env.REACT_APP_SERVER_URL}/answers/${id}`
            
                    // answer found or at least not initialized yet
                    }).then((res) => {

                        // answer found
                        if (res.status === 200 && res.data.answer) {
                            setUpProductForUser(
                                res.data.answer.sectionIndex,
                                res.data.answer.pageNum,
                                res.data.answer.params
                            );
                            savedAnswer.current = res.data.answer;
                            setIsLoading(false);
    
                        // answer not found: initialize one
                        } else {

                            Axios({
                                method: "POST",
                                data: {
                                    language: langs.lang,
                                    params: myParams.current
                                },
                                withCredentials: true,
                                url: `${process.env.REACT_APP_SERVER_URL}/answers/${id}`
                    
                            // answer initialized
                            }).then((res) => {
                                if ((res.status === 201 || res.status === 200) && res.data.answer) {
                                    savedAnswer.current = res.data.answer.result;
                                    setIsLoading(false);
            
                                // an error ocurred
                                } else {
                                    setShowError(true);
                                }
                    
                            // an error ocurred
                            }).catch((error) => {
                                setShowError(true);
                            });
                        }
            
                    // an error ocurred
                    }).catch((error) => {
                        setShowError(true);
                    });
                
                // product not found
                } else {
                    navigate("/404");
                }
            }

        // product not found
		}).catch((error) => {
            navigate("/404");
		});

        // prevent double fetch on development caused by StrictMode
        return () => {
            ignore = true;
        };

    }, [id, navigate, langs, setLinkParams]);


    /* --- API response: initialize product --- */
    useEffect(() => {
        if (product) {
            // set up section options
            const actualSection = product.content[sectionIndex];
            const optional = actualSection.find(productOption => {
                const logic = productOption.settings.displayLogic;
                const urlKey = myParams.current[logic.params?.key];
                if (logic.type === "url" && urlKey === logic.params?.value && urlKey) {
                    return true;
                }
                return false;
            });
            // option found
            if (optional) {
                setSection(optional);
            // set up default section
            } else {
                const defaultSection = actualSection.find(productOption => {
                    const logic = productOption.settings.displayLogic;
                    if (logic.type === "default") return true;
                    return false;
                });
                setSection(defaultSection);
            }
            // breadcrumbs
            if (product.path && product.path.segment) {
                setBreadcrumbName(product.path.name[langs.lang]);
                setBreadcrumbSegment(product.path.segment[langs.lang]);
                setShowBreadcrumbs(true);
            } else {
                setShowBreadcrumbs(false);
            }
        }
    }, [langs.lang, product, sectionIndex]);


    /* --- set up section when needed --- */
    useEffect(() => {
        if (section) {
            setShowSection(true);
        }
    }, [product, section, sectionIndex]);


    /* --- API call: update answers collection --- */
    useEffect(() => {
        if (updateAnswerData) {
            setIsLoading(true);
            // save extras given by children
            let updateData;
            if (saveExtras.current && typeof saveExtras.current === "object") {
                updateData = {
                    ...updateAnswerData,
                    ...saveExtras.current
                }
            } else {
                updateData = updateAnswerData;
            }
            // api
            Axios({
                method: "POST",
                data: updateData,
                withCredentials: true,
                url: `${process.env.REACT_APP_SERVER_URL}/answers/${id}/${savedAnswer.current._id}`
            // answer initialized
            }).then((res) => {
                if (res.status === 201) {
                    savedAnswer.current = res.data.result;
                    if (updateData.finishTime) {
                        if (product.path?.url) {
                            let pathURL = `/paths/${product.path.url}`;
                            if (seeResults.ref) pathURL = `/results/${product.path.url}`;
                            navigate(pathURL);
                        } else {
                            navigate("/");
                        }
                    }
                // an error ocurred
                } else {
                    setShowError(true);
                }
                setIsLoading(false);
            // an error ocurred
            }).catch((error) => {
                setShowError(true);
                setIsLoading(false);
            });
            saveExtras.current = undefined;
        }
    }, [id, updateAnswerData, navigate, product]);


    /* --- page change: set up nav buttons --- */
    useEffect(() => {
        if (section.pages.length) {

            // reset buttons
            setShowBackBtn(false);
            setShowNextBtn(false);
            setShowStartBtn(false);
            setShowFinishBtn(false);

            // show needed nav buttons
            if (section.settings.navButtons || forceNavButtons) {
                const pagesTotal = section.pages.length;
                // if it's not the last
                if (pageNum < pagesTotal) {
                    setShowNextBtn(true);
                }
                // if it's not the first
                if (pageNum > 1) {
                    setShowBackBtn(true);
                }
                // if it's last page
                if (pageNum === pagesTotal) {
                    // last section
                    if (product.content.length - 1 === sectionIndex) {
                        setShowFinishBtn(true);
                    // is not last section
                    } else {
                        setShowStartBtn(true);
                    }
                }
            }

            // save page and section in the db
            if (savedAnswer.current &&
                savedAnswer.current._id &&
                (savedAnswer.current.pageNum < pageNum ||
                savedAnswer.current.sectionIndex < sectionIndex)
            ) {
                let newData = {
                    pageNum: pageNum,
                    sectionIndex: sectionIndex
                };
                // update progress
                section.pages[pageNum - 1].find((pageComponent) => {
                    if (pageComponent.component === "ProgressBar") {
                        newData.progress = parseInt(pageComponent.props.to);
                        return true;
                    } else {
                        if (pageComponent.props.initialProgressTo) {
                            newData.progress = parseInt(pageComponent.props.initialProgressTo);
                            return true;
                        }
                    }
                    return false;
                });
                setUpdateAnswerData(newData);
            }
        }
    }, [pageNum, section, sectionIndex, product, id, forceNavButtons]);

    
    /* --- product header buttons --- */


    /* --- back button event --- */
    const backBtn = () => {
        setForceNavButtons(false);
        setPageNum((prevPage) => {
            if (prevPage > 1) {
                return prevPage - 1;
            }
            return prevPage;
        });
    };

    /* --- next button event --- */
    const nextBtn = () => {
        setForceNavButtons(false);
        setPageNum((prevPage) => {
            const totalPages = section.pages.length;
            if (prevPage < totalPages) {
                return prevPage + 1;
            }
            return prevPage;
        });
    };

    /* --- start new section button --- */
    const startBtn = () => {
        setForceNavButtons(false);
        setSectionIndex(prevSectionIndex => prevSectionIndex + 1);
        setPageNum(1);
    };

    const finishProduct = () => {
        /* --- data layer --- */
        const tagManagerArgs = {
            dataLayer: {
                event: 'surveyFinished',
                page: window.location.href
            }
        }
        TagManager.dataLayer(tagManagerArgs);
        setUpdateAnswerData({
            progress: 100,
            finishTime: true
        });
    };

    /* --- finish button event --- */
    const finishBtn = () => {
        finishProduct();
    };

    /* --- finish and go to results --- */
    const finishAndResults = () => {
        seeResults.ref = true;
        finishProduct();
    };

    /* --- Fake next button to be used by child components --- */
    const fakeNext = () => {
        setFakeNextClicked(Date.now());
    };

    // logic in one place for next, finish and start
    const productContinue = () => {
        const pagesTotal = section.pages.length;
        // next page
        if (pageNum < pagesTotal) {
            nextBtn();
        }
        // last page
        if (pageNum === pagesTotal) {
            // last section
            if (product.content.length - 1 === sectionIndex) {
                finishBtn();
            // is not last section
            } else {
                startBtn();
            }
        }
    };


    /* --- render breadcrumbs --- */
    const renderBreadcrumbs = (bread, crumbs) => {
        return (
            <div className="product-breadcrumbs">
                <NavLink to={`/paths/${product.path.url}`}>{bread}</NavLink>
                <span className="product-breadcrumb-separator"> / </span>
                <span className="product-breadcrumbs-segment">{crumbs}</span>
            </div>
        );
    };


    /* --- renders dynamic modules --- */
    const renderProduct = (pageNums) => {
        const pages = pageNums.map( (page, pageIndex) => {
            return page.map((component, componentIndex) => {
                if (pageNum === pageIndex + 1) {
                    const DynamicComponent = lazyImport(component.component);
                    return (
                        <DynamicComponent 
                            {...component.props}
                            key={componentIndex}
                            savedAnswer={savedAnswer.current}
                            savedAnswerRef={savedAnswer}
                            goNext={productContinue}
                            finishAndResults={finishAndResults}
                            displayError={setShowError}
                            setIsLoading={setIsLoading}
                            setUpdateAnswerData={setUpdateAnswerData}
                            saveExtras={saveExtras}
                            setForceNavButtons={setForceNavButtons}
                            setShowFakeBtn={setShowFakeBtn}
                            fakeNextClicked={fakeNextClicked}
                        />
                    );
                }
                return "";
            });
        });
        return pages;
    };
	
	
    /* --- RENDER --- */
	return (
        <div className="product-component">
            {/* --- Product header --- */}
            <header>
                <div className="product-header-titles">
                    {showBreadcrumbs && renderBreadcrumbs(
                        breadcrumbName,
                        breadcrumbSegment
                    )}
                    <h1>{product?.settings.name[langs.lang]}</h1>
                </div>
                <div className="product-nav-buttons">
                    {
                        showBackBtn &&
                            <button onClick={backBtn} className="button">
                                {langs.text.generic.back[langs.lang]}
                            </button>
                    }
                    {
                        showNextBtn &&
                            <button onClick={nextBtn} className="button">
                                {langs.text.generic.next[langs.lang]}
                            </button>
                    }
                    {
                        showStartBtn &&
                            <button onClick={startBtn} className="button bg-color-1">
                                {langs.text.generic.next[langs.lang]}
                            </button>
                    }
                    {
                        showFinishBtn &&
                            <button onClick={finishBtn} className="button bg-color-1">
                                {langs.text.generic.finish[langs.lang]}
                            </button>
                    }
                    {
                        showFakeBtn &&
                            <button onClick={fakeNext} className="button bg-color-1">
                                {langs.text.generic.next[langs.lang]}
                            </button>
                    }
                </div>
            </header>
            {/* --- Product content --- */}
            <section className="product-container">
                <Suspense fallback={<div />}>
                    {/* --- Section --- */}
                    {showSection && renderProduct(section.pages)}
                </Suspense>
            </section>
            {/* --- Error screen --- */}
            {showError && <Error title={errorText.title} paragraph={errorText.paragraph} />}
            {/* --- Loading screen --- */}
            {isLoading && <Loading classes="loading-absolute" />}
        </div>
	);
}