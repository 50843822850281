import { useContext, useState, useEffect } from "react";
import { LangContext } from "../App.js";

export default function ProgressBar({from = 0, to = 0, classes = ""}) {

	const [progress, setProgress] = useState(from);

	/* --- lang context --- */
    const {langs} = useContext(LangContext);

	useEffect(() => {
		setTimeout(
			() => setProgress(to), 300
		);
	}, [to]);

	// component
	return (
		<div className={"component-progress-bar " + classes}>
			<div className="progress-bar-container">
				<div className="progress-bar-color" style={{width: progress + "%"}}></div>
			</div>
			<div className="progress-bar-text">{parseInt(to) + "%"} {langs.text.generic.completed[langs.lang]}</div>
		</div>
	);
}