import { useContext, useState } from "react";
import { LangContext } from "./App.js";
import { NavLink } from "react-router-dom";

export default function Menu() {

    /* --- language context --- */
    const {langs} = useContext(LangContext);

    /* --- state for mobile menu --- */
    const [mobileOpened, setMobileOpened] = useState(false);

    const openerClicked = e => {
        mobileOpened ? setMobileOpened(false) : setMobileOpened(true);
    };
    const openerEntered = e => {
        // setMobileOpened(true);
    };
    const openerLeaved = e => {
        setMobileOpened(false);
    };

    return (
        <div className="menu-component" onClick={openerClicked} onMouseEnter={openerEntered} onMouseLeave={openerLeaved}>
            <button
                className={
                    "menu-component-opener" +
                    (mobileOpened ? " opened" : "")
                }
                onClick={openerClicked}
            >
                <span></span>
            </button>
            <div
                className={
                    "menu-component-items" + 
                    (mobileOpened ? " opened" : "")
                }
            >
                <NavLink to="/paths">{langs.text.learningPath.pathMenuName[langs.lang]}</NavLink>
                <NavLink to="/results">{langs.text.results.resultsMenuName[langs.lang]}</NavLink>
            </div>
        </div>
    );
}