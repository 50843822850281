import { useContext } from "react";
import { LangContext } from "./App.js";

export default function Error({classes = "", title = "", paragraph = ""}) {

    const {langs} = useContext(LangContext);

    const reload = () => {
        window.location.reload();
    };

    return (
        <div className={"error-component " + classes}>
            <div className="forms">
                <h1>{title || langs.text.generic.errorTitle[langs.lang]}</h1>
                <p className="primary-color">{paragraph || langs.text.generic.errorDescription[langs.lang]}</p>
                <button onClick={reload} className="button">{langs.text.generic.reload[langs.lang]}</button>
            </div>
        </div>
    );
}