import { useContext, useState, useEffect } from "react";
import { LangContext, UserContext } from "./App.js";
import { Link, useNavigate, Navigate } from "react-router-dom";
import Axios from "axios";
import Loading from "./Loading";
import TagManager from 'react-gtm-module';

export default function Register() {

	/* --- redirects --- */
	const navigate = useNavigate();

    /* --- lang context --- */
    const {langs} = useContext(LangContext);

	/* --- user context --- */
    const {user} = useContext(UserContext);

    /* --- states for fields --- */
    const [regName, setRegName] = useState("");
	const [regLastname, setRegLastname] = useState("");
	const [regEmail, setRegEmail] = useState("");
	const [regPassword, setRegPassword] = useState("");
	const [regGroup, setRegGroup] = useState("");
	const [registerResult, setRegisterResult] = useState("");
	const [legalAccepted, setLegalAccepted] = useState("");
	const [isLoading, setIsLoading] = useState(false);

	/* --- data layer tag manager --- */
    useEffect(() => {
        /* --- data layer --- */
        const tagManagerArgs = {
            dataLayer: {
                event: 'pageview',
                page: window.location.href
            }
        }
        TagManager.dataLayer(tagManagerArgs);
    }, []);

    /* --- Register API --- */
    const register = () => {

		// accept legal notice
		if (!legalAccepted) {
			addErrorsClass();
			setRegisterResult(
				langs.text.account.legalError[langs.lang]
			); 
			return;
		}

		// loading screen
		setIsLoading(true);

		// register API
		Axios({
			method: "POST",
			data: {
				name: regName,
				lastname: regLastname,
				email: regEmail,
				password: regPassword,
				lang: langs.lang,
				group: regGroup
			},
			withCredentials: true,
			url: `${process.env.REACT_APP_SERVER_URL}/users`,

		// register success
		}).then((res) => {

			// log in user API
			Axios({
				method: "POST",
				data: {
					email: regEmail,
					password: regPassword
				},
				withCredentials: true,
				url: `${process.env.REACT_APP_SERVER_URL}/login`,

			// log in success
			}).then((res) => {

				// get user data API
				const setUserFunc = user.setUser;
				Axios({
					method: "GET",
					withCredentials: true,
					url: `${process.env.REACT_APP_SERVER_URL}/users`,

				// data success
				}).then(
					(res) => {
						const userData = res.data.user;
						user.setUser({
							isLogged: true,
							user: userData,
							setUser: setUserFunc
						});
						// data layer
						const tagManagerArgs = {
							dataLayer: {
								event: 'registration'
							}
						}
						TagManager.dataLayer(tagManagerArgs);
						// redirect
						redirect("/");
					}

				// data error
				).catch(
					(error) => {
						user.setUser({
							isLogged: false,
							user: {},
							setUser: setUserFunc
						});
						redirect("/login");
					}
				);	

			// log in error
			}).catch((error) => {
				// redirect to log in page
				redirect("/login");
			});

		// registration errors
		}).catch((error) => {

			// display errors
			addErrorsClass();
			if(error.response?.data?.errors?.length > 0) {
				setRegisterResult(
					error.response.data.errors.map(er => {
						return langs.text.account[`${er}Error`][langs.lang] + " "
					})
				);
			} else {
				setRegisterResult(
					langs.text.generic.genericFormError[langs.lang]
				);
			}
			setIsLoading(false);
		});
	};

	/* --- every time the language is changed --- */
    useEffect(() => {
        // errors do not translate, this sets them empty
        setRegisterResult("");
    }, [langs.lang]);

    /* --- prevent form submit --- */
    const submitForm = (e) => {
        e.preventDefault();
    };

	/* --- navigate --- */
	const redirect = (location) => {
		navigate(location);
	};

	/* --- Legal Button --- */
    const acceptLegal = (legalStatus) => {
		// false has to be an empty string to be able to style the submit button
		setLegalAccepted(legalStatus ? true : "");
	};

	/* --- Add errors class to form (useful to animate) --- */
	const addErrorsClass = () => {
		const form = document.querySelector(".forms form");
		form.classList.add("errors");
		setTimeout(() => {
			form.classList.remove("errors");
		}, 500);
	};

	/* --- Render component --- */
    return (
        <section className="register-component forms">
			{user.isLogged && <Navigate to="/" />}
            <h1>
				{langs.text.account.createAccount[langs.lang]}
			</h1>
            <p className="after-title">
				{langs.text.account.or[langs.lang]}
				<Link to="/login">{langs.text.account.logIn[langs.lang]}</Link>
			</p>
            <form onSubmit={submitForm}>
                <input 
					type="text"
					placeholder={langs.text.account.name[langs.lang]}
					onChange={e => setRegName(e.target.value)} />
                <input
					type="text"
					placeholder={langs.text.account.lastname[langs.lang]}
					onChange={e => setRegLastname(e.target.value)} />
                <input
					type="email"
					placeholder={langs.text.account.email[langs.lang]}
					onChange={e => setRegEmail(e.target.value)} />
                <input
					type="password"
					placeholder={langs.text.account.password[langs.lang]}
					onChange={e => setRegPassword(e.target.value)} />
                <input
					type="text"
					placeholder={langs.text.account.groupCode[langs.lang]}
					onChange={e => setRegGroup(e.target.value)} />
                <label className="forms-check-label">
					<input
						type="checkbox"
						onChange={e => acceptLegal(e.target.checked)} />
					<span dangerouslySetInnerHTML={{__html: langs.text.account.legal[langs.lang]}} />
				</label>
				{!isLoading && 
					<button
						onClick={register}
						className={
							"button" +
							(regName && regEmail && regPassword && legalAccepted && " bg-color-1")
							}>
							{langs.text.account.signUp[langs.lang]}
					</button>
				}
				{isLoading && <Loading classes="button-loading" />}
                <p className="forms-error">{registerResult}</p>
            </form>
		</section>
    );
}