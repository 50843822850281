import logo from "../images/startiq-logo.svg";

export default function Logo() {
	
	// component
	return (
        <a href="https://app.startiq.org" className="logo-component">
            <img src={logo} alt="StartIQ Logo" />
        </a>
	);
}