import { useContext } from "react";
import { LangContext } from "./App.js";

export default function NotFound() {

    const {langs} = useContext(LangContext);

    return (
        <div className="not-found-component forms">
            <h1>{langs.text.generic.pageNotFoundTitle[langs.lang]}</h1>
            <p className="primary-color">{langs.text.generic.pageNotFoundDescription[langs.lang]}</p>
        </div>
    );
}