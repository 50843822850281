import {useEffect} from 'react';
import {useLocation} from "react-router-dom";

const UseRedirectToHttps = () => {
    let location = useLocation();
    useEffect(() => {
        if (window.location.hostname !== "localhost" && window.location.hostname !== "127.0.0.1") {
			if (window.location.protocol !== "https:") {
				window.location.replace(`https://app.startiq.org${location.pathname}`);
			}
		}
    })
};

export default UseRedirectToHttps;