import LANGUAGES from "./languagesText.js";

export default class Language {

    constructor() {
        this.languagesText = LANGUAGES;
    }

    getText() {
        return this.languagesText;
    }

}